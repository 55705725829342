export default [
  // GRIDS
  {
    text: 'Grid 1',
    value: require("@/assets/img/background/gray/3.png"),
  },
  {
    text: 'Grid 2',
    value: require("@/assets/img/background/gray/4.png"),
  },
  {
    text: 'Grid 3',
    value: require("@/assets/img/background/gray/5.png"),
  },
  {
    text: 'Paper 1',
    value: require("@/assets/img/background/gray/1.png"),
  },
  {
    text: 'Paper 2',
    value: require("@/assets/img/background/gray/2.png"),
  },
  {
    text: 'Abstract 1',
    value: require("@/assets/img/background/gray/6.png"),
  },
  {
    text: 'Abstract 2',
    value: require("@/assets/img/background/gray/7.png"),
  },
  {
    text: 'Abstract 3',
    value: require("@/assets/img/background/gray/8.png"),
  },
]
