<template>
  <div>

    <Navbar
      :showSettingsButton="false"
      :showHomeButton="false"
    ></Navbar> 

    <GenericHeader :title="$t('view.settings.header')"></GenericHeader>

    <SettingsBox
      ref="settingsBox"
    ></SettingsBox>

  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import GenericHeader from '@/components/GenericHeader.vue'
import SettingsBox from '@/components/SettingsBox.vue'

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  title() {
    return this.$t('view.settings.name')
  },
  components: {
    Navbar,
    GenericHeader,
    SettingsBox,
  },
  methods: {
    ...mapActions([
    ]),
  },

  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
      backgroundColor: (state) => state.settings.backgroundColor,
      backgroundImg: (state) => state.settings.backgroundImg,
    }),

    ...mapGetters([
    ])
  },
    beforeRouteEnter(to, from, next) {
    console.log('entered before route hook');
    next(vm => {
      console.log('entered next in before route enter hook');
      vm.$refs.settingsBox.bgColor = vm.backgroundColor.hex;
      vm.$refs.settingsBox.bgImg = vm.backgroundImg;
      vm.$refs.settingsBox.darkThemeCheck = vm.isDarkTheme
    })
  }
}

</script>