<template>
	<b-container>
		<b-row align-h="center">
			<b-col cols="10" sm="7" md="6" lg="5">
				<div class="settings rounded mb-3">

					<!-- DARK MODE -->
					<span class="d-block font-weight-light settings-header mx-2">{{
						$t("view.settings.display")
					}}</span>
					<div class="m-2 theme-checkbox">
						<b-form-checkbox
							@change="checkDarkTheme"
							id="darkmode"
              v-model="darkThemeCheck"
							name="darkmode"
							:value="true"
							:unchecked-value="false"
						>
							{{ $t("view.settings.darkmode") }}
						</b-form-checkbox>
					</div>

          <!-- BACKGROUND IMG -->
					<div class="settings-select m-2">
						<b-form inline>
							<label class="mr-sm-2" for="bgImg"
								>{{ $t("view.settings.backgroundImg") }}:</label
							>
							<b-form-select
                @change="checkBgImg"
                v-model="bgImg"
								id="bgImg"
								class="mb-2 mr-sm-2 mb-sm-0"
								:options="bgImgOptions"
							></b-form-select>
						</b-form>
					</div>

          <!-- BACKGROUND COLOR -->
					<div class="settings-select m-2">
						<label 
              :class="[ darkThemeCheck ? 'text-decoration-line-through' : '', 'mr-sm-2']" 
              for="bgColor"
							>{{ $t("view.settings.backgroundColor") }}:</label
						>
						<b-form-input
              :disabled="darkThemeCheck"
              @change="checkBgColor"
							id="bgColor"
							class="mb-2 mr-sm-2 mb-sm-0"
							type="color"
							v-model="bgColor"
						></b-form-input>
					</div>

					<!-- CHANGE LANGUAGE -->
					<span class="d-block font-weight-light settings-header mx-2">{{
						$t("view.settings.locale")
					}}</span>
					<div class="m-2">
						<LocaleChanger></LocaleChanger>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>

// import bg img names from dedicated javascript file (that way i only need to change 1 thing)
// in app.vue we're already setting the path to the bg img, thus we only need the names
// or we might include the path directly within the variable. it'll just be an array
// of the path name ended by the name

import bgImgData from '@/data/bgImgData';
import LocaleChanger from "@/components/LocaleChanger.vue";
import { mapState, mapActions } from "vuex";

export default {
	data() {
		return {
      darkThemeCheck: '',
      bgImg: '',
			bgColor: '',

			bgImgOptions: [
				{
					text: this.$t('view.settings.none'),
					value: null,
				},
        ...bgImgData
			],
		};
	},
	components: {
		LocaleChanger,
	},
	methods: {
		...mapActions([
			"changeDarkTheme",
      "changeBgImg",
      "changeBgColor",
      "resetBg",
		]),

    checkBgImg(payload) {
      console.log('bgimgpayload',payload)
      this.changeBgImg(payload);
    },

    checkBgColor(payload) {
      console.log('bgcolorpayload',payload)
      this.changeBgColor(payload);
    },

		checkDarkTheme(payload) {
      console.log('darkmodepayload',payload)
			if (payload == true) {
        
        // turn on the dark theme
				this.changeDarkTheme(true);
        // also reset bg color to dark theme colors
        this.bgColor = this.darkThemeBgColor;
        this.changeBgColor(this.darkThemeBgColor);
			} 
      
      else {

        // turn off dark theme
				this.changeDarkTheme(false);

        // also reset bg color to whatever the previously selected bgColor was
        this.changeBgColor(this.bgColor);
			}
		},
	},
	computed: {
		...mapState({
			devMode: (state) => state.settings.devMode,
      darkThemeBgColor: (state) => state.settings.darkThemeBgColor,
      reset: (state) => state.settings.reset,

		}),
	},
};
</script>
